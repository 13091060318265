var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var IconUnCheckBox = function () {
    return (_jsx("div", { style: {
            background: "#FFFFFF",
            border: "2px solid #CCCCCC",
            borderRadius: "5px",
            width: "28px",
            height: "28px",
        } }));
};
export var IconCheckedBox = function () {
    return (_jsx("div", __assign({ style: {
            background: "#DDE3F0",
            border: "2px solid #1D439B",
            borderRadius: "5px",
            width: "28px",
            height: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }, "data-testid": "icon-checked" }, { children: _jsx("svg", __assign({ width: "20", height: "14", viewBox: "0 0 20 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M7.23749 13.7165L0.791656 7.27067L2.36249 5.72692L7.23749 10.6019L17.6104 0.229004L19.1812 1.77275L7.23749 13.7165Z", fill: "#1D439B" }) })) })));
};
export var IconIndeterminate = function () {
    return (_jsx("div", __assign({ style: {
            background: "#DDE3F0",
            border: "2px solid #1D439B",
            borderRadius: "5px",
            width: "28px",
            height: "28px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        } }, { children: _jsx("svg", __assign({ width: "16", height: "4", viewBox: "0 0 16 4", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M0.156433 3.07273V0.927246H15.8436V3.07273H0.156433Z", fill: "#1D439B" }) })) })));
};
