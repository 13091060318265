var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { keyCloakClient } from "../../../services/Instances/KeyCloakServices";
import ROUTES from "../../../routes/contants/Routes";
import { CustomAlert } from "../../layout/CustomAlert";
import { ALERT_TYPE } from "../../layout/CustomAlert/types";
import { SelectLanguage } from "../../components/SelectLanguage";
import { LanguageType } from "../../components/SelectLanguage/types";
import { i18n } from "../../../i18n";
import { UNAUTHORIZED, PKCE_METHOD, ROLE_SYSTEM } from "../../constants";
import { filterUnknownRoles } from "./convertRolesLogin";
import { Logo } from "../../assets/icons/Logo";
import "./style.scss";
/* global process */
function Login() {
    var _a, _b;
    var history = useHistory();
    var t = useTranslation().t;
    var queryParams = new URLSearchParams((_a = history === null || history === void 0 ? void 0 : history.location) === null || _a === void 0 ? void 0 : _a.search);
    var logoutCode = queryParams.get("logoutCode");
    var _c = useAuth(), setIsAuthenticated = _c.setIsAuthenticated, setValidToken = _c.setValidToken, setUserNameInfo = _c.setUserNameInfo, setIsShowTermsConditions = _c.setIsShowTermsConditions;
    var _d = useState({
        type: "",
        message: "",
    }), messageAlert = _d[0], setMessageAlert = _d[1];
    useEffect(function () {
        keyCloakClient
            .init({
            checkLoginIframe: false,
            pkceMethod: PKCE_METHOD,
        })
            .then(function () {
            var _a, _b, _c, _d, _e, _f;
            if (keyCloakClient.token) {
                if (!keyCloakClient.realmAccess) {
                    setMessageAlert({
                        type: ALERT_TYPE.error,
                        message: t("failRole"),
                    });
                    return;
                }
                else {
                    var data = filterUnknownRoles(keyCloakClient.realmAccess.roles);
                    if (data.length === 0 || data.length > 1) {
                        setMessageAlert({
                            type: ALERT_TYPE.error,
                            message: t("failRole"),
                        });
                        return;
                    }
                    else {
                        if (data[0] === ROLE_SYSTEM.NWA) {
                            setMessageAlert({
                                type: ALERT_TYPE.error,
                                message: t("failRole"),
                            });
                            return;
                        }
                        else {
                            if ((_a = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _a === void 0 ? void 0 : _a.locale) {
                                window.localStorage.setItem("language", keyCloakClient.tokenParsed.locale);
                                i18n.changeLanguage(keyCloakClient.tokenParsed.locale);
                            }
                            setIsShowTermsConditions(!!((_b = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _b === void 0 ? void 0 : _b.web_terms));
                            setIsAuthenticated(true);
                            setUserNameInfo({
                                userName: ((_c = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _c === void 0 ? void 0 : _c.full_name)
                                    ? (_d = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _d === void 0 ? void 0 : _d.full_name
                                    : (_e = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _e === void 0 ? void 0 : _e.email,
                                isEmail: !((_f = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _f === void 0 ? void 0 : _f.full_name),
                            });
                            setValidToken({
                                accessToken: (keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.token) || "",
                                role: data[0] || "",
                                refreshToken: (keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.refreshToken) || "",
                            });
                            return history.push({
                                pathname: ROUTES.HOME,
                                state: { role: data[0] },
                            });
                        }
                    }
                }
            }
        })
            .catch(function () {
            setMessageAlert({
                type: ALERT_TYPE.error,
                message: t("failKeycloak"),
            });
        });
    }, [history, setIsAuthenticated, setValidToken]);
    useEffect(function () {
        if (logoutCode === UNAUTHORIZED) {
            setMessageAlert({
                type: ALERT_TYPE.error,
                message: t("authorize"),
            });
        }
    }, [(_b = history === null || history === void 0 ? void 0 : history.location) === null || _b === void 0 ? void 0 : _b.search]);
    var handleLogin = function () {
        keyCloakClient
            .init({
            redirectUri: "".concat(process.env.FRONTEND_DOMAIN_PUBLIC_URL).concat(process.env.FRONTEND_LOGIN_PATH),
        })
            .then(function () {
            var setLanguage = window.localStorage.getItem("language");
            if (setLanguage) {
                setLanguage =
                    setLanguage === LanguageType.English
                        ? LanguageType.English
                        : LanguageType.Georgian;
                keyCloakClient.login({ locale: setLanguage });
            }
            else {
                keyCloakClient.login();
            }
        })
            .catch(function () {
            setMessageAlert({
                type: ALERT_TYPE.error,
                message: t("failKeycloak"),
            });
            keyCloakClient.logout();
        });
    };
    var handleClearMessage = function () {
        setMessageAlert({
            type: "",
            message: "",
        });
    };
    return (_jsxs(Container, __assign({ maxWidth: "sm" }, { children: [_jsx(CustomAlert, { router: ROUTES.LOGIN, isOpen: !!messageAlert.message, severity: messageAlert.type === ALERT_TYPE.success ? "success" : "error", message: messageAlert.message, onClearMessage: handleClearMessage }), _jsx(Box, __assign({ className: "select-language-login" }, { children: _jsx(SelectLanguage, { isOnlySelect: true }) })), _jsxs(Box, __assign({ sx: {
                    height: "calc(100vh - 104px)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                } }, { children: [_jsx(Logo, {}), _jsx(Box, __assign({ sx: {
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "90px",
                        } }, { children: _jsx(Button, __assign({ variant: "contained", sx: {
                                width: "350px",
                                padding: "15px 25px",
                                borderRadius: "30px",
                                backgroundColor: "#1D439B",
                                color: "#FFFFFF",
                                fontSize: "16px",
                                textTransform: "unset",
                            }, onClick: handleLogin, "data-testid": "btn-login" }, { children: t("login") })) }))] }))] })));
}
export { Login };
