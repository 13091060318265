var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useRef } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { i18n } from "../../../i18n";
import { LanguageType } from "./types";
import { IconExpand } from "../../assets/icons/iconExpandDown";
import { FlagEnglish, FlagGeorgian } from "../../assets/icons/IconFlagLanguage";
import { IconNotSelected, IconSelected } from "../../assets/icons/IconSelected";
import "./style.scss";
import useWindowSize from "../../hooks/useWindowSize";
var SelectLanguage = function (_a) {
    var _b, _c, _d, _e;
    var isOnlySelect = _a.isOnlySelect;
    var t = useTranslation().t;
    var history = useHistory();
    var _f = useState(function () {
        var storedLang = window.localStorage.getItem("language");
        if (storedLang) {
            return storedLang === LanguageType.English
                ? LanguageType.English
                : LanguageType.Georgian;
        }
        else {
            // @TODO post-pilot - foconnor: Ideally wouldn't store this now so change in browser lang would be reflected
            // but currently have issues with Keycloak language detection so right now this is easiest for pilot.
            var toStore = navigator.language.startsWith(LanguageType.English)
                ? LanguageType.English
                : LanguageType.Georgian;
            window.localStorage.setItem("language", toStore);
            return toStore;
        }
    }), lang = _f[0], setLang = _f[1];
    var windowSize = useWindowSize();
    var queryParams = new URLSearchParams((_b = history === null || history === void 0 ? void 0 : history.location) === null || _b === void 0 ? void 0 : _b.search);
    var locale = queryParams.get("kc_locale");
    var isSelectChangeLanguage = useRef(false);
    var elementSelect = useRef(null);
    useEffect(function () {
        if ((locale === LanguageType.English || locale === LanguageType.Georgian) &&
            !isSelectChangeLanguage.current) {
            i18n.changeLanguage(locale);
            window.localStorage.setItem("language", locale);
            setLang(locale);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_c = history === null || history === void 0 ? void 0 : history.location) === null || _c === void 0 ? void 0 : _c.search, locale, i18n.language]);
    var options = [
        {
            flag: FlagEnglish,
            label: t("english"),
            value: t("english"),
            valueType: LanguageType.English,
        },
        {
            flag: FlagGeorgian,
            label: t("georgian"),
            value: t("georgian"),
            valueType: LanguageType.Georgian,
        },
    ];
    var handleChangeLanguage = function (lang) {
        var language = lang === t("english") ? LanguageType.English : LanguageType.Georgian;
        i18n.changeLanguage(language);
        window.localStorage.setItem("language", language);
        isSelectChangeLanguage.current = true;
        setLang(language);
    };
    var positionOption = ((_d = elementSelect.current) === null || _d === void 0 ? void 0 : _d.getBoundingClientRect())
        ? windowSize.width - ((_e = elementSelect.current) === null || _e === void 0 ? void 0 : _e.getBoundingClientRect().right)
        : 0;
    return (_jsx(_Fragment, { children: isOnlySelect ? (_jsx(Select, __assign({ ref: elementSelect, value: lang === LanguageType.English ? t("english") : t("georgian"), onChange: function (e) {
                return handleChangeLanguage(e.target.value);
            }, renderValue: function (selected) { return (_jsx(Box, __assign({ sx: {
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                } }, { children: selected === t("english") ? _jsx(FlagEnglish, {}) : _jsx(FlagGeorgian, {}) }))); }, sx: {
                height: "52px",
                width: "80px",
            }, className: "select-icon", IconComponent: function (_props) {
                var rotate = _props.className.toString().includes("iconOpen");
                return (_jsx("div", __assign({ style: {
                        position: "absolute",
                        cursor: "pointer",
                        pointerEvents: "none",
                        right: rotate ? 5 : 5,
                        top: rotate ? 10 : 15,
                        transform: rotate ? "rotate(180deg)" : "none",
                    } }, { children: _jsx(IconExpand, {}) })));
            }, MenuProps: {
                sx: {
                    ".MuiPaper-root": {
                        right: "".concat(positionOption, "px"),
                        left: "unset !important",
                    },
                },
            }, "data-testid": "select-language" }, { children: options &&
                options.length > 0 &&
                options.map(function (el) { return (_jsx(MenuItem, __assign({ value: el.value, sx: {
                        fontWeight: "500",
                        minWidth: "350px",
                        height: "52px",
                        overflowY: "hidden",
                    }, "data-testid": el.valueType }, { children: _jsxs(Box, __assign({ className: "select-content" }, { children: [_jsxs(Box, __assign({ className: "select-label" }, { children: [_jsx(el.flag, {}), _jsx("p", { children: el.label })] })), lang === el.valueType ? (_jsx(IconSelected, {})) : (_jsx(IconNotSelected, {}))] })) }), el.value)); }) }))) : (_jsx(Box, { children: _jsxs(FormControl, __assign({ className: "select-language-container" }, { children: [_jsx(InputLabel, __assign({ id: "lable-select-language" }, { children: t("language") })), _jsx(Select, __assign({ value: lang === LanguageType.English ? t("english") : t("georgian"), onChange: function (e) {
                            return handleChangeLanguage(e.target.value);
                        }, renderValue: function (selected) { return (_jsxs(Box, __assign({ sx: {
                                display: "flex",
                                my: "10px",
                                mx: "20px",
                                alignItems: "center",
                                fontWeight: 500,
                            } }, { children: [selected === t("english") ? (_jsx(FlagEnglish, {})) : (_jsx(FlagGeorgian, {})), _jsx("p", __assign({ className: "language-selected" }, { children: selected }))] }))); }, sx: {
                            height: "52px",
                        }, IconComponent: function (_props) {
                            var rotate = _props.className.toString().includes("iconOpen");
                            return (_jsx("div", __assign({ style: {
                                    position: "absolute",
                                    cursor: "pointer",
                                    pointerEvents: "none",
                                    right: rotate ? 10 : 10,
                                    top: rotate ? 10 : 15,
                                    transform: rotate ? "rotate(180deg)" : "none",
                                } }, { children: _jsx(IconExpand, {}) })));
                        }, "data-testid": "select-language" }, { children: options &&
                            options.length > 0 &&
                            options.map(function (el) { return (_jsx(MenuItem, __assign({ value: el.value, sx: {
                                    fontWeight: "500",
                                    height: "52px",
                                    overflowY: "hidden",
                                }, "data-testid": el.valueType }, { children: _jsxs(Box, __assign({ className: "select-content" }, { children: [_jsxs(Box, __assign({ className: "select-label" }, { children: [_jsx(el.flag, {}), _jsx("p", { children: el.label })] })), lang === el.valueType ? (_jsx(IconSelected, {})) : (_jsx(IconNotSelected, {}))] })) }), el.value)); }) }))] })) })) }));
};
export { SelectLanguage };
