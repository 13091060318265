var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState, } from "react";
import { keyCloakClient } from "../services/Instances/KeyCloakServices";
import { PKCE_METHOD, ROLE_SYSTEM } from "../ui/constants";
import ROUTES from "../routes/contants/Routes";
import { filterUnknownRoles } from "../ui/pages/Login/convertRolesLogin";
export var initialAuthContext = {
    isAuthenticated: false,
    validToken: undefined,
    isLoadingKeyCloak: true,
    userNameInfo: undefined,
    isShowTermsConditions: false,
    setValidToken: function () { },
    setIsAuthenticated: function () { },
    setUserNameInfo: function () { },
    setIsShowTermsConditions: function () { },
};
export var AuthContext = createContext(initialAuthContext);
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useState(true), isLoadingKeyCloak = _b[0], setIsLoadingKeyCloak = _b[1];
    var _c = useState(false), isAuthenticated = _c[0], setIsAuthenticated = _c[1];
    var _d = useState(undefined), validToken = _d[0], setValidToken = _d[1];
    var _e = useState(undefined), userNameInfo = _e[0], setUserNameInfo = _e[1];
    var _f = useState(false), isShowTermsConditions = _f[0], setIsShowTermsConditions = _f[1];
    useEffect(function () {
        if (window.location.pathname !== ROUTES.LOGIN) {
            keyCloakClient
                .init({
                checkLoginIframe: true,
                pkceMethod: PKCE_METHOD,
                onLoad: "check-sso",
            })
                .then(function (value) {
                var _a, _b, _c, _d, _e, _f, _g;
                setIsLoadingKeyCloak(false);
                var data = filterUnknownRoles((_b = (_a = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.realmAccess) === null || _a === void 0 ? void 0 : _a.roles) !== null && _b !== void 0 ? _b : []);
                if (data.length === 1 && data[0] !== ROLE_SYSTEM.NWA) {
                    setUserNameInfo({
                        userName: ((_c = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _c === void 0 ? void 0 : _c.full_name)
                            ? (_d = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _d === void 0 ? void 0 : _d.full_name
                            : (_e = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _e === void 0 ? void 0 : _e.email,
                        isEmail: !((_f = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _f === void 0 ? void 0 : _f.full_name),
                    });
                    setIsShowTermsConditions(!!((_g = keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.tokenParsed) === null || _g === void 0 ? void 0 : _g.web_terms));
                    setIsAuthenticated(value);
                    setValidToken({
                        accessToken: (keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.token) || "",
                        role: data[0] || "",
                        refreshToken: (keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.refreshToken) || "",
                    });
                }
            })
                .catch(function () {
                setIsLoadingKeyCloak(false);
            });
        }
    }, []);
    var authContextValue = {
        isAuthenticated: isAuthenticated,
        isLoadingKeyCloak: isLoadingKeyCloak,
        validToken: validToken,
        userNameInfo: userNameInfo,
        isShowTermsConditions: isShowTermsConditions,
        setValidToken: setValidToken,
        setIsAuthenticated: setIsAuthenticated,
        setUserNameInfo: setUserNameInfo,
        setIsShowTermsConditions: setIsShowTermsConditions,
    };
    return (_jsx(AuthContext.Provider, __assign({ value: authContextValue }, { children: children })));
};
export var useAuth = function () { return useContext(AuthContext); };
