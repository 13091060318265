var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { RefreshTokenService } from "./RefreshTokenService";
import { keyCloakClient } from "./Instances/KeyCloakServices";
/* global process */
var axiosClient = axios.create({
    baseURL: process.env.BOLNISI_BACKEND_API,
    headers: {
        "Content-Type": "application/json",
    },
});
var AxiosInterceptor = function (_a) {
    var children = _a.children;
    var _b = useAuth(), validToken = _b.validToken, isAuthenticated = _b.isAuthenticated, setValidToken = _b.setValidToken;
    var refreshToken = useRef(validToken === null || validToken === void 0 ? void 0 : validToken.refreshToken);
    var resInterceptor = function (response) {
        return response;
    };
    var errInterceptor = function (error) { return __awaiter(void 0, void 0, void 0, function () {
        var originalRequest, isRefreshTokenErrorApi, data, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(error.response.status === 401)) return [3 /*break*/, 4];
                    originalRequest = error.config;
                    isRefreshTokenErrorApi = error.config.url.includes("refresh-token");
                    if (!(error.response.status === 401 &&
                        !originalRequest._retry &&
                        !isRefreshTokenErrorApi &&
                        refreshToken.current)) return [3 /*break*/, 4];
                    originalRequest._retry = true;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, RefreshTokenService.refreshToken(refreshToken.current)];
                case 2:
                    data = (_c.sent()).data;
                    originalRequest.headers.Authorization = "Bearer ".concat(data === null || data === void 0 ? void 0 : data.access_token);
                    originalRequest.headers["Content-Type"] =
                        ((_b = (_a = error.config) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.constructor.name) === "FormData"
                            ? "multipart/form-data"
                            : "application/json";
                    setValidToken({
                        accessToken: (data === null || data === void 0 ? void 0 : data.access_token) || "",
                        role: (validToken === null || validToken === void 0 ? void 0 : validToken.role) || "",
                        refreshToken: (data === null || data === void 0 ? void 0 : data.refresh_token) || "",
                    });
                    return [2 /*return*/, axiosClient(originalRequest)];
                case 3:
                    error_1 = _c.sent();
                    keyCloakClient.logout({
                        redirectUri: "".concat(process.env.FRONTEND_DOMAIN_PUBLIC_URL).concat(process.env.FRONTEND_LOGIN_PATH, "?").concat(process.env.REACT_APP_REDIRECT_KEY_CLOAK_LOGOUT_AUTHORIZE_QUERY),
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, Promise.reject(error.response)];
            }
        });
    }); };
    axiosClient.interceptors.request.use(function (config) {
        var _a, _b;
        if (validToken === null || validToken === void 0 ? void 0 : validToken.accessToken) {
            config.headers.Authorization =
                (_b = (_a = config === null || config === void 0 ? void 0 : config.headers) === null || _a === void 0 ? void 0 : _a.Authorization) !== null && _b !== void 0 ? _b : "Bearer ".concat(validToken.accessToken);
        }
        return config;
    });
    useEffect(function () {
        refreshToken.current = validToken === null || validToken === void 0 ? void 0 : validToken.refreshToken;
    }, [validToken === null || validToken === void 0 ? void 0 : validToken.refreshToken]);
    useEffect(function () {
        if (isAuthenticated) {
            axiosClient.interceptors.response.use(resInterceptor, errInterceptor);
        }
    }, [isAuthenticated]);
    return _jsx(_Fragment, { children: children });
};
export { AxiosInterceptor, axiosClient };
