import { axiosClient } from "./AxiosInterceptor";
/* global process */
export var RefreshTokenService = {
    refreshToken: function (refreshToken) {
        var url = "".concat(process.env.KEYCLOAK_HOST, "/realms/").concat(process.env.KEYCLOAK_REALM_NAME, "/protocol/openid-connect/token");
        return axiosClient.post(url, {
            client_id: "".concat(process.env.KEY_CLOAK_CLIENT_CLIENT_ID),
            grant_type: "refresh_token",
            refresh_token: refreshToken,
        }, {
            headers: { "content-type": "application/x-www-form-urlencoded" },
        });
    },
};
