var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var IconUploadFile = function (_a) {
    var width = _a.width, height = _a.height, color = _a.color;
    return (_jsx("svg", __assign({ width: width !== null && width !== void 0 ? width : "142", height: height !== null && height !== void 0 ? height : "106", viewBox: "0 0 142 106", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M35.2391 105.208C25.6719 105.208 17.4433 101.782 10.5534 94.9309C3.66365 88.0793 0.21875 79.8904 0.21875 70.3642C0.21875 61.8859 2.8263 54.3271 8.04141 47.6876C13.2565 41.048 20.0737 37.0444 28.4931 35.6767C30.812 25.3958 35.9484 17.0229 43.9023 10.5578C51.8563 4.09266 61.0017 0.860107 71.3384 0.860107C83.3275 0.860107 93.4536 5.13432 101.717 13.6828C109.98 22.2312 114.347 32.5313 114.818 44.5829V48.3329C122.495 48.6545 128.908 51.4945 134.057 56.8529C139.207 62.2115 141.781 68.8506 141.781 76.7704C141.781 84.6007 138.956 91.2979 133.306 96.862C127.657 102.426 120.943 105.208 113.167 105.208H78.2488C74.8671 105.208 71.944 103.977 69.4794 101.514C67.0149 99.0504 65.7827 96.156 65.7827 92.8303V56.5804L52.3722 70.0792L44.9469 62.7421L71 36.6007L97.1414 62.7421L89.6278 70.0792L76.2173 56.5804V92.8303H113.199C117.571 92.8303 121.365 91.2263 124.58 88.0184C127.796 84.8105 129.403 81.0023 129.403 76.5939C129.403 72.1419 127.799 68.3228 124.592 65.1367C121.384 61.9505 117.576 60.3575 113.167 60.3575H102.528V46.261C102.528 37.259 99.4761 29.5193 93.3716 23.0421C87.267 16.5649 79.7194 13.3262 70.7288 13.3262C61.6071 13.3262 53.9869 16.6743 47.8683 23.3704C41.7496 30.0665 38.6903 38.0205 38.6903 47.2324H34.9266C28.6878 47.2324 23.4065 49.4189 19.0825 53.7917C14.7585 58.1644 12.5966 63.5719 12.5966 70.0143C12.5966 76.2485 14.8112 81.6097 19.2405 86.0979C23.6698 90.5862 29.0174 92.8303 35.2833 92.8303H56.4077V105.208H35.2391Z", fill: color !== null && color !== void 0 ? color : "#1D439B" }) })));
};
