var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconDropDown } from "../../assets/icons/IconDropDown";
import { IconCheck } from "../../assets/icons/IconCheck";
import { IconDelete } from "../../assets/icons/IconDelete";
import { ROLE_SYSTEM } from "../../constants/auth";
import "./style.scss";
var BulkComponent = function (_a) {
    var selected = _a.selected, onFinalise = _a.onFinalise, onDelete = _a.onDelete, onApprove = _a.onApprove, role = _a.role;
    var t = useTranslation().t;
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        if (selected.length <= 0)
            return;
        setAnchorEl(event.currentTarget);
    };
    var handleApprove = function () {
        if (selected.length <= 0)
            return;
        onApprove();
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleFinalise = function () {
        onFinalise();
        setAnchorEl(null);
    };
    var handleDelete = function () {
        onDelete();
        setAnchorEl(null);
    };
    return (_jsx(_Fragment, { children: role === ROLE_SYSTEM.ADMIN || role === ROLE_SYSTEM.PROVIDER ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ "data-testid": "bulk-action-menu", className: ["box-bulk", selected.length > 0 ? "selected" : ""].join(" "), onClick: function (e) { return handleClick(e); } }, { children: [_jsx("p", __assign({ className: "text" }, { children: t("bulkAction") })), selected.length > 0 && (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: "count" }, { children: "(".concat(selected.length, ")") })), _jsx(IconDropDown, {})] }))] })), _jsxs(Menu, __assign({ open: open, anchorEl: anchorEl, onClose: handleClose, id: "basic-menu", MenuListProps: {
                        "aria-labelledby": "basic-button",
                    }, sx: {
                        "& .MuiPaper-root": {
                            minWidth: "350px",
                            marginTop: "10px",
                            ul: {
                                padding: 0,
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                            },
                        },
                    } }, { children: [_jsxs(MenuItem, __assign({ "data-testid": "button-finalise-all", className: "menu-item", sx: {
                                paddingInline: "20px",
                                borderBottom: "1px solid #ccc",
                            }, onClick: handleFinalise }, { children: [_jsx(IconCheck, { color: "#20C198", width: "22" }), _jsx("p", { children: t("finaliseAll", { selected: selected.length }) })] })), _jsxs(MenuItem, __assign({ "data-testid": "button-delete-all", className: "menu-item", sx: {
                                paddingInline: "20px",
                            }, onClick: handleDelete }, { children: [_jsx(IconDelete, {}), _jsx("p", { children: t("deleteAll", { selected: selected.length }) })] }))] }))] })) : (_jsxs("div", __assign({ className: [
                "box-bulk-winery",
                selected.length > 0 ? "selected" : "",
            ].join(" "), "data-testid": "button-approve-all", onClick: handleApprove }, { children: [selected.length > 0 && _jsx(IconCheck, { color: "#FFFFFF" }), _jsx("p", __assign({ className: "text", style: { marginLeft: selected.length > 0 ? "10px" : "0px" } }, { children: t("approve") })), selected.length > 0 && (_jsx("p", __assign({ className: "count" }, { children: "(".concat(selected.length, ")") })))] }))) }));
};
export { BulkComponent };
