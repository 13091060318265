var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Modal, Typography, } from "@mui/material";
import { IconClose } from "../../assets/icons/IconClose";
import "./style.scss";
import { IconExpand } from "../../assets/icons/iconExpandDown";
import { IconCloseToastBulk } from "../../assets/icons/IconCloseToastBulk";
export var ModalToastBulk = function (props) {
    var t = useTranslation().t;
    return (_jsx(Modal, __assign({ open: props.isModal, onClose: props.onClose }, { children: _jsxs("div", __assign({ className: "modal-toast-bulk" }, { children: [_jsx("div", __assign({ className: "btn-close", onClick: props.onClose }, { children: _jsx(IconClose, {}) })), _jsx(Box, __assign({ className: props.data.isError ? "text-header-error" : "text-header-success" }, { children: props.data.textHeader })), _jsx(Box, __assign({ className: "error-container", "data-testid": "error-container" }, { children: _jsxs(Accordion, __assign({ className: "error-content" }, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(IconExpand, {}), className: "container-header-error" }, { children: _jsxs(Box, __assign({ className: "content-header-error" }, { children: [_jsx(IconCloseToastBulk, {}), _jsx(Typography, { children: props.data.textError })] })) })), _jsx(Box, __assign({ className: "error-content-body", sx: {
                                    marginBottom: props.data.dataError.length > 1 ? "10px" : "0px",
                                } }, { children: props.data.dataError.map(function (item, index) { return (_jsx(AccordionDetails, __assign({ className: index === 0
                                        ? "first-content"
                                        : index === props.data.dataError.length - 1
                                            ? "last-content"
                                            : "", sx: {
                                        borderBottom: props.data.dataError.length === 1
                                            ? "unset"
                                            : "1px solid #CCCCCC",
                                    }, "data-testid": "content-detail" }, { children: _jsxs(Box, __assign({ className: "content-item" }, { children: [_jsxs(Typography, { children: [t("lotNumber"), ": ", item.id] }), _jsx(Typography, { children: t(item.status) })] })) }), item.id)); }) }))] })) })), _jsx(Button, __assign({ variant: "contained", fullWidth: true, className: "confirm", onClick: props.onClose, "data-testid": "btn-close" }, { children: t("ok") }))] })) })));
};
