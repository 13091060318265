var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Link } from "@mui/material";
import "./style.scss";
import { useTranslation } from "react-i18next";
import ROUTES from "../../../routes/contants/Routes";
import { useAuth } from "../../../contexts/AuthContext";
import useWindowSize from "../../hooks/useWindowSize";
function Footer() {
    var windowSize = useWindowSize();
    var _a = useAuth(), isShowTermsConditions = _a.isShowTermsConditions, isAuthenticated = _a.isAuthenticated;
    var t = useTranslation().t;
    var handleClickViewTermsConditions = function () {
        window.open("".concat(ROUTES.VIEW_TERMS_CONDITIONS_FRONTEND), "_blank");
    };
    var handleClickViewPrivacyPolicy = function () {
        window.open("".concat(ROUTES.VIEW_PRIVACY_POLICY), "_blank");
    };
    if (!isShowTermsConditions &&
        isAuthenticated &&
        window.location.pathname === ROUTES.HOME) {
        return null;
    }
    var isCheckRenderLinkText = window.location.pathname === ROUTES.VIEW_TERMS_CONDITIONS_FRONTEND ||
        window.location.pathname === ROUTES.VIEW_TERMS_CONDITIONS_MOBILE ||
        window.location.pathname === ROUTES.VIEW_TERMS_CONDITIONS_API ||
        window.location.pathname === ROUTES.VIEW_PRIVACY_POLICY;
    return (_jsx(Container, __assign({ maxWidth: false, sx: { backgroundColor: "#F6F6F6" } }, { children: _jsxs("div", __assign({ className: isCheckRenderLinkText ? "box-footer-not-link" : "box-footer" }, { children: [_jsx("p", __assign({ className: "title-footer" }, { children: t("footerCopyright") })), isCheckRenderLinkText ? (_jsx(_Fragment, {})) : (_jsxs("div", __assign({ className: "box-link" }, { children: [_jsx(Link, __assign({ "data-testid": "viewTerms", onClick: handleClickViewTermsConditions }, { children: t("terms") })), _jsx(Link, __assign({ "data-testid": "viewPrivacy", onClick: handleClickViewPrivacyPolicy }, { children: t("privacy") }))] })))] })) })));
}
export { Footer };
