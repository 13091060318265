var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var IconClose = function () {
    return (_jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-testid": "icon-close" }, { children: _jsx("path", { d: "M7.26248 22.3711L5.62915 20.7377L12.3667 14.0002L5.62915 7.26273L7.26248 5.62939L14 12.3669L20.7375 5.62939L22.3708 7.26273L15.6333 14.0002L22.3708 20.7377L20.7375 22.3711L14 15.6336L7.26248 22.3711Z", fill: "#363C4A" }) })));
};
