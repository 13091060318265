var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var IconCloseToastBulk = function () {
    return (_jsx("svg", __assign({ width: "19", height: "19", viewBox: "0 0 19 19", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M6.6208 13.3L9.50005 10.4208L12.3793 13.3L13.3001 12.3793L10.4208 9.50001L13.3001 6.62075L12.3793 5.70001L9.50005 8.57926L6.6208 5.70001L5.70005 6.62075L8.57931 9.50001L5.70005 12.3793L6.6208 13.3ZM9.5016 17.6963C8.37388 17.6963 7.31169 17.4826 6.31502 17.0552C5.31836 16.6278 4.4477 16.0417 3.70302 15.297C2.95835 14.5524 2.3723 13.6822 1.94486 12.6866C1.51743 11.6909 1.30371 10.6293 1.30371 9.50155C1.30371 8.36064 1.51743 7.29185 1.94486 6.29518C2.3723 5.29853 2.95777 4.43096 3.70127 3.6925C4.44477 2.95403 5.3146 2.36941 6.31079 1.93864C7.30697 1.50787 8.36923 1.29248 9.49758 1.29248C10.6391 1.29248 11.7087 1.50758 12.7062 1.93777C13.7037 2.36796 14.5715 2.95179 15.3094 3.68927C16.0473 4.42675 16.6315 5.29397 17.0619 6.29093C17.4924 7.28788 17.7076 8.35758 17.7076 9.50001C17.7076 10.6288 17.4922 11.6914 17.0614 12.6879C16.6306 13.6845 16.046 14.5546 15.3076 15.2984C14.5691 16.0422 13.702 16.6278 12.7064 17.0552C11.7108 17.4826 10.6425 17.6963 9.5016 17.6963ZM9.50005 16.1285C11.3438 16.1285 12.9095 15.4837 14.1971 14.1942C15.4847 12.9047 16.1285 11.34 16.1285 9.50001C16.1285 7.65623 15.4847 6.09053 14.1971 4.80293C12.9095 3.51532 11.342 2.87152 9.49445 2.87152C7.66015 2.87152 6.09775 3.51532 4.80727 4.80293C3.5168 6.09053 2.87157 7.65809 2.87157 9.50561C2.87157 11.3399 3.51633 12.9023 4.80585 14.1928C6.09537 15.4833 7.6601 16.1285 9.50005 16.1285Z", fill: "#C93B49" }) })));
};
