var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import { useCallback, useRef, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IconClose } from "../../assets/icons/IconClose";
import { IconUploadFile } from "../../assets/icons/IconUploadFile";
import { CSV_FILE_TYPE } from "../../constants";
import { UploadService } from "../../../services";
import { ALERT_TYPE } from "../../layout/CustomAlert/types";
import { convertErrorMessage } from "../../utils/MessageUtils";
import "./style.scss";
var ACCESS_FILE = ".csv";
export var ModalUpload = function (_a) {
    var isOpen = _a.isOpen, onCancel = _a.onCancel, _b = _a.title, title = _b === void 0 ? "Upload a SCM CSV file" : _b, onOk = _a.onOk, onShowMessage = _a.onShowMessage, wineryId = _a.wineryId;
    var t = useTranslation().t;
    var inputRef = useRef(null);
    var _c = useState(false), disable = _c[0], setDisable = _c[1];
    var _d = useState(true), disableDone = _d[0], setDisableDone = _d[1];
    var _e = useState(null), file = _e[0], setFile = _e[1];
    var colorDisable = disable ? "#DEDEDE" : undefined;
    var handleUploadFile = useCallback(function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 5, 6, 7]);
                    setDisable(true);
                    setFile(file);
                    res = void 0;
                    if (!(title === t("scmDataTitle"))) return [3 /*break*/, 2];
                    return [4 /*yield*/, UploadService.uploadWinerySCM(wineryId, file)];
                case 1:
                    res = _c.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, UploadService.uploadBottleMapping(wineryId, file)];
                case 3:
                    res = _c.sent();
                    _c.label = 4;
                case 4:
                    if (res) {
                        setDisableDone(false);
                        setDisable(true);
                        onShowMessage &&
                            onShowMessage(ALERT_TYPE.success, t("uploadSuccess"));
                    }
                    return [3 /*break*/, 7];
                case 5:
                    err_1 = _c.sent();
                    setDisable(false);
                    setFile(null);
                    if ((_b = (_a = err_1 === null || err_1 === void 0 ? void 0 : err_1.data) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.code) {
                        onShowMessage &&
                            onShowMessage(ALERT_TYPE.error, t(convertErrorMessage(err_1.data.meta.code)));
                    }
                    else {
                        onShowMessage &&
                            onShowMessage(ALERT_TYPE.error, t("somethingWentWrongPleaseTryAgain"));
                    }
                    return [3 /*break*/, 7];
                case 6:
                    if (inputRef.current) {
                        inputRef.current.value = "";
                    }
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); }, [onShowMessage, wineryId, title]);
    var handleChangeFile = useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            event.preventDefault();
            if (event.target instanceof HTMLInputElement) {
                if (!event.target.files)
                    return [2 /*return*/];
                handleUploadFile(event.target.files[0]);
            }
            return [2 /*return*/];
        });
    }); }, [handleUploadFile]);
    var handleDrag = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
    }, []);
    var handleDrop = useCallback(function (e) {
        var _a, _b, _c, _d, _e, _f;
        e.preventDefault();
        e.stopPropagation();
        if (disable)
            return;
        if (!CSV_FILE_TYPE.includes((_a = e.dataTransfer) === null || _a === void 0 ? void 0 : _a.files[0].type)) {
            onShowMessage && onShowMessage(ALERT_TYPE.error, t("wrongFormat"));
            return;
        }
        if (((_b = e.dataTransfer) === null || _b === void 0 ? void 0 : _b.files) && e.dataTransfer.files.length > 1) {
            onShowMessage &&
                onShowMessage(ALERT_TYPE.error, t("uploadMultipleFile"));
            return;
        }
        if (((_c = e.dataTransfer) === null || _c === void 0 ? void 0 : _c.files) &&
            ((_d = e.dataTransfer) === null || _d === void 0 ? void 0 : _d.files[0]) &&
            CSV_FILE_TYPE.includes((_e = e.dataTransfer) === null || _e === void 0 ? void 0 : _e.files[0].type)) {
            handleUploadFile((_f = e.dataTransfer) === null || _f === void 0 ? void 0 : _f.files[0]);
        }
    }, [disable, handleUploadFile, onShowMessage]);
    return (_jsx(Modal, __assign({ open: isOpen, onClose: onCancel, slotProps: {
            backdrop: !disable
                ? {}
                : {
                    onClick: function (event) {
                        event.stopPropagation();
                    },
                },
        } }, { children: _jsxs("form", __assign({ className: "modal-box", onDragEnter: handleDrag }, { children: [_jsx("input", { "data-testid": "input-upload-file", accept: ACCESS_FILE, className: "input-upload", ref: inputRef, type: "file", onChange: function (e) { return handleChangeFile(e); }, multiple: false }), !disable && (_jsx("div", __assign({ className: "btn-close", onClick: onCancel }, { children: _jsx(IconClose, {}) }))), _jsx("p", __assign({ className: "title" }, { children: title })), _jsxs("div", __assign({ className: "drag-drop-container", onDrop: handleDrop, onDragEnter: handleDrag, onDragLeave: handleDrag, onDragOver: handleDrag, "data-testid": "drop-area" }, { children: [_jsx(IconUploadFile, { width: "141", height: "104", color: colorDisable }), _jsx(Box, __assign({ sx: {
                                fontWeight: "bold",
                                fontSize: 20,
                                mt: 4,
                                color: colorDisable,
                            } }, { children: t("dragAndDropAFileHere") })), _jsx(Box, __assign({ sx: { color: colorDisable, my: 2, fontSize: "18px" } }, { children: t("or") })), _jsx(Button, __assign({ sx: {
                                backgroundColor: "#1d439b",
                                color: "#fff",
                                py: 2,
                                px: 3,
                                borderRadius: 30,
                                height: 52,
                                textTransform: "unset",
                            }, disabled: disable, variant: "contained", onClick: function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }, { children: t("browseFiles") }))] })), _jsx(Box, __assign({ "data-testid": "file-name", sx: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        my: 2,
                        fontWeight: 500,
                        color: "#030321",
                    } }, { children: file === null || file === void 0 ? void 0 : file.name })), _jsx(Button, __assign({ disabled: disableDone, variant: "contained", fullWidth: true, className: "confirm", onClick: onOk, sx: {
                        color: disableDone ? "#363C4A !important" : "#F6F6F6",
                    } }, { children: t("done") }))] })) })));
};
