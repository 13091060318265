var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var IconDropDown = function (props) {
    return (_jsx("svg", __assign({ width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: props.style }, { children: _jsx("path", { d: "M11 14.3916L5.1792 8.57081L6.48545 7.28748L11 11.825L15.5146 7.31039L16.8209 8.59373L11 14.3916Z", fill: "#F6F6F6" }) })));
};
