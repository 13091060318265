import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Route } from "react-router-dom";
import ROUTES from "./contants/Routes";
import { Home } from "../ui/pages/Home";
import { Login } from "../ui/pages/Login";
import { Page404 } from "../ui/pages/Error404";
import { PrivateRoute } from "./PrivateRoute";
import { ViewBottleMappingFile } from "../ui/pages/ViewBottleMapping";
import { TermsConditionsFrontend } from "../ui/pages/TermsConditionsFrontend";
import { PrivacyPolicy } from "../ui/pages/PrivacyPolicy";
import { TermsConditionsMobile } from "../ui/pages/TermsConditionsMobile";
import { TermsConditionsAPI } from "../ui/pages/TermsConditionsApi";
var Routes = function () {
    return (_jsxs(Switch, { children: [_jsx(Route, { path: ROUTES.LOGIN, exact: true, component: Login }), _jsx(PrivateRoute, { path: ROUTES.HOME, exact: true, Component: Home }), _jsx(PrivateRoute, { path: ROUTES.VIEW_BOTTLE, exact: true, Component: ViewBottleMappingFile }), _jsx(Route, { path: ROUTES.VIEW_TERMS_CONDITIONS_FRONTEND, exact: true, component: TermsConditionsFrontend }), _jsx(Route, { path: ROUTES.VIEW_TERMS_CONDITIONS_MOBILE, exact: true, component: TermsConditionsMobile }), _jsx(Route, { path: ROUTES.VIEW_TERMS_CONDITIONS_API, exact: true, component: TermsConditionsAPI }), _jsx(Route, { path: ROUTES.VIEW_PRIVACY_POLICY, exact: true, component: PrivacyPolicy }), _jsx(Route, { path: ROUTES.PAGE_404, exact: true, component: Page404 })] }));
};
export default Routes;
