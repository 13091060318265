var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { Alert } from "@mui/material";
import "./style.scss";
import { ALERT_TYPE } from "./types";
import { keyCloakClient } from "../../../services/Instances/KeyCloakServices";
import ROUTES from "../../../routes/contants/Routes";
var CustomAlert = function (_a) {
    var severity = _a.severity, message = _a.message, router = _a.router, _b = _a.autoCloseDelay, autoCloseDelay = _b === void 0 ? 5000 : _b, isOpen = _a.isOpen, isfailLots = _a.isfailLots, onClearMessage = _a.onClearMessage;
    useEffect(function () {
        if (isOpen) {
            var timer_1 = setTimeout(function () {
                onClearMessage && onClearMessage();
                if (router === ROUTES.LOGIN && (keyCloakClient === null || keyCloakClient === void 0 ? void 0 : keyCloakClient.token)) {
                    keyCloakClient.logout();
                }
            }, autoCloseDelay);
            return function () {
                clearTimeout(timer_1);
            };
        }
    }, [autoCloseDelay, isOpen, onClearMessage, router]);
    if (!isOpen) {
        return null;
    }
    return (_jsx(Alert, __assign({ severity: severity, className: "custom-alert ".concat(severity === ALERT_TYPE.success ? "alert-success" : "alert-error"), sx: {
            top: isfailLots ? "130px" : "",
        } }, { children: message })));
};
export { CustomAlert };
