var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var convertMessageAlert = function (response) {
    var _a, _b, _c, _d, _e;
    var succeed = [];
    var error = [];
    var reason = [];
    if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.failLotsAlreadyApproved) &&
        response.data.failLotsAlreadyApproved.length) {
        reason = ["failLotsAlreadyApproved"];
        error = response.data.failLotsAlreadyApproved.map(function (id) { return ({
            id: id,
            status: "failLotsAlreadyApproved",
        }); });
    }
    if (((_b = response.data) === null || _b === void 0 ? void 0 : _b.failLotsNotFinalised) &&
        response.data.failLotsNotFinalised.length) {
        reason.push("failLotsNotFinalised");
        error = __spreadArray(__spreadArray([], error, true), response.data.failLotsNotFinalised.map(function (id) { return ({
            id: id,
            status: "failLotsNotFinalised",
        }); }), true);
    }
    if (((_c = response.data) === null || _c === void 0 ? void 0 : _c.failLotsNotFound) &&
        response.data.failLotsNotFound.length) {
        reason.push("failLotsNotFound");
        error = __spreadArray(__spreadArray([], error, true), response.data.failLotsNotFound.map(function (id) { return ({
            id: id,
            status: "failLotsNotFound",
        }); }), true);
    }
    if (((_d = response.data) === null || _d === void 0 ? void 0 : _d.failLotsAlreadyFinalised) &&
        response.data.failLotsAlreadyFinalised.length) {
        reason.push("failLotsAlreadyFinalised");
        error = __spreadArray(__spreadArray([], error, true), response.data.failLotsAlreadyFinalised.map(function (id) { return ({
            id: id,
            status: "failLotsAlreadyFinalised",
        }); }), true);
    }
    if (response.status === 200) {
        if ((_e = response.data) === null || _e === void 0 ? void 0 : _e.failJobsNotScheduled) {
            succeed = response.data.succeed.concat(response.data.failJobsNotScheduled);
        }
        else {
            succeed = response.data.succeed;
        }
        if (error.length) {
            return {
                isModalToastBulk: true,
                quantitySuccess: succeed.length,
                quantityError: error.length,
                dataErrors: error,
            };
        }
        return {
            isModalToastBulk: false,
            quantitySuccess: succeed.length,
        };
    }
    else {
        if (reason.length > 1) {
            return {
                isModalToastBulk: true,
                quantityError: error.length,
                dataErrors: error,
            };
        }
        return {
            isModalToastBulk: false,
            quantityError: error.length,
            reason: reason[0],
        };
    }
};
