export var convertName = function (userName, isEmail) {
    if (!userName)
        return null;
    if (isEmail) {
        return userName === null || userName === void 0 ? void 0 : userName.substring(0, 1).toUpperCase();
    }
    else {
        var textConvert = userName.split(" ");
        if (textConvert.length > 1) {
            return (textConvert[0].substring(0, 1) + textConvert[1].substring(0, 1)).toUpperCase();
        }
        return userName === null || userName === void 0 ? void 0 : userName.substring(0, 1).toUpperCase();
    }
};
