var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { axiosClient } from "./AxiosInterceptor";
import { BASE_URL } from "./contants";
export var UploadService = {
    uploadWinerySCM: function (wineryId, data, config) {
        var formData = new FormData();
        formData.append("data", data);
        return axiosClient.post("".concat(BASE_URL).concat(wineryId), formData, __assign({ headers: {
                "content-type": "multipart/form-data",
            } }, config));
    },
    uploadBottleMapping: function (wineryId, data, config) {
        var formData = new FormData();
        formData.append("data", data);
        return axiosClient.post("api/v1/bottles/".concat(wineryId), formData, __assign({ headers: {
                "content-type": "multipart/form-data",
            } }, config));
    },
};
