var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import Container from "@mui/material/Container";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ViewBottleMappingDataSCMService } from "../../../services/";
import "./style.scss";
function ViewBottleMappingFile() {
    var _this = this;
    var t = useTranslation().t;
    var id = useParams().id;
    var _a = useState(), dataViewBottle = _a[0], setDataViewBottle = _a[1];
    var _b = useState(), dataViewBottleError = _b[0], setDataViewBottleError = _b[1];
    var handleClickViewBottle = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ViewBottleMappingDataSCMService.viewBottleMapping(id)];
                case 1:
                    response = _a.sent();
                    setDataViewBottle(response === null || response === void 0 ? void 0 : response.data);
                    window.dispatchEvent(new Event("getViewBottleComplete"));
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setDataViewBottleError(error_1 === null || error_1 === void 0 ? void 0 : error_1.data);
                    window.dispatchEvent(new Event("getViewBottleComplete"));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        handleClickViewBottle();
    }, []);
    return (_jsxs(Container, __assign({ maxWidth: false, className: "content-viewBottles", "data-testid": "view-bottles" }, { children: [dataViewBottle && (_jsxs(_Fragment, { children: [_jsxs("p", __assign({ "data-testid": "content-scheduled" }, { children: [t("scheduled"), ":", " ", JSON.stringify(dataViewBottle.scheduled, null, 5)] })), _jsxs("p", __assign({ "data-testid": "content-success" }, { children: [t("success"), ": ", JSON.stringify(dataViewBottle.success, null, 5)] })), _jsxs("p", __assign({ "data-testid": "content-error" }, { children: [t("error"), ": ", JSON.stringify(dataViewBottle.error, null, 5)] }))] })), dataViewBottleError && _jsx("p", { children: JSON.stringify(dataViewBottleError) })] })));
}
export { ViewBottleMappingFile };
