var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Button } from "@mui/material";
import Popover from "@mui/material/Popover";
import { useTranslation } from "react-i18next";
import { SelectLanguage } from "../SelectLanguage";
import { useAuth } from "../../../contexts/AuthContext";
import "./style.scss";
import { keyCloakClient } from "../../../services/Instances/KeyCloakServices";
import { convertName } from "../../utils/convertName";
export var PopUpLanguage = function (props) {
    var t = useTranslation().t;
    var userNameInfo = useAuth().userNameInfo;
    var handleClose = function () {
        props.onCancel();
    };
    var handleLogout = function () {
        try {
            keyCloakClient.logout({
                redirectUri: "".concat(process.env.FRONTEND_DOMAIN_PUBLIC_URL).concat(process.env.FRONTEND_LOGIN_PATH),
            });
        }
        catch (error) {
            props.setMessageAlert();
        }
    };
    return (_jsx(Popover, __assign({ open: props.isModal, anchorEl: props.anchorEl, onClose: handleClose, anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, transformOrigin: {
            vertical: "bottom",
            horizontal: "right",
        }, PaperProps: { className: "popover-language" } }, { children: _jsxs("div", __assign({ className: "popup-language" }, { children: [(userNameInfo === null || userNameInfo === void 0 ? void 0 : userNameInfo.userName) && (_jsxs(Box, __assign({ className: "user-content", "data-testid": "content-user" }, { children: [_jsx(Avatar, __assign({ className: "avatar-name" }, { children: convertName(userNameInfo.userName, userNameInfo.isEmail) })), _jsx("p", { children: userNameInfo.userName })] }))), _jsxs(Box, __assign({ sx: { marginTop: (userNameInfo === null || userNameInfo === void 0 ? void 0 : userNameInfo.userName) ? "unset" : "80px" } }, { children: [_jsx(SelectLanguage, {}), _jsx(Button, __assign({ variant: "contained", fullWidth: true, className: "confirm", onClick: handleLogout, "data-testid": "btn-logout" }, { children: t("signOut") }))] }))] })) })));
};
