export var WINDOW_SIZE_WIDTH = {
    TABLET: 990,
    MOBILE: 650,
    SMALL_MOBILE: 410,
};
export var HEIGHT_FOOTER = {
    NORMAL: 80,
    RESPONSIVE: 160,
    RESPONSIVE_SMALL_MOBILE: 180,
};
export var BREAKPOINT_FOOTER = 790;
