var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var IconDocument = function () {
    return (_jsx("svg", __assign({ width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M7.31029 16.2938H14.6895V14.7813H7.31029V16.2938ZM7.31029 12.398H14.6895V10.8855H7.31029V12.398ZM5.15612 20.4875C4.66723 20.4875 4.24327 20.308 3.88424 19.949C3.52522 19.59 3.3457 19.166 3.3457 18.6771V3.32296C3.3457 2.81879 3.52522 2.38719 3.88424 2.02817C4.24327 1.66914 4.66723 1.48962 5.15612 1.48962H13.452L18.677 6.71462V18.6771C18.677 19.166 18.4974 19.59 18.1384 19.949C17.7794 20.308 17.3478 20.4875 16.8436 20.4875H5.15612ZM12.5124 7.58546V3.32296H5.15612V18.6771H16.8436V7.58546H12.5124Z", fill: "#030321" }) })));
};
