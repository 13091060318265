var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var IconSelected = function () {
    return (_jsxs("svg", __assign({ width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("circle", { cx: "16", cy: "16", r: "16", fill: "#20C198" }), _jsx("path", { d: "M13.45 22.2L7.5 16.25L8.95 14.825L13.45 19.325L23.025 9.75L24.475 11.175L13.45 22.2Z", fill: "#F6F6F6" })] })));
};
export var IconNotSelected = function () {
    return (_jsx("svg", __assign({ width: "32", height: "32", viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("circle", { cx: "16", cy: "16", r: "15", stroke: "#CCCCCC", strokeWidth: "2" }) })));
};
