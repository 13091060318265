import { useState, useEffect } from "react";
function useWindowSize() {
    var _a = useState({
        width: 0,
        height: 0,
    }), windowSize = _a[0], setWindowSize = _a[1];
    useEffect(function () {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        if (window) {
            window.addEventListener("load", handleResize);
            window.addEventListener("resize", handleResize);
            window.addEventListener("orientationchange", handleResize);
            window.addEventListener("getViewBottleComplete", handleResize);
            handleResize();
            return function () {
                window.removeEventListener("load", handleResize);
                window.removeEventListener("resize", handleResize);
                window.addEventListener("orientationchange", handleResize);
                window.addEventListener("getViewBottleComplete", handleResize);
            };
        }
    }, []);
    return windowSize;
}
export default useWindowSize;
