var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var IconDelete = function () {
    return (_jsx("svg", __assign({ width: "22", height: "23", viewBox: "0 0 22 23", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-testid": "icon-delete" }, { children: _jsx("path", { d: "M4.48086 22.9122C3.82967 22.9122 3.27903 22.6888 2.82895 22.2419C2.37887 21.7951 2.15383 21.2484 2.15383 20.6017V4.25695H0.677734V1.94643H6.86999V0.774658H15.114V1.94643H21.3228V4.25695H19.8467V20.6017C19.8467 21.2338 19.618 21.7769 19.1606 22.231C18.7032 22.6851 18.1563 22.9122 17.5196 22.9122H4.48086ZM17.5196 4.25695H4.48086V20.6017H17.5196V4.25695ZM7.49011 18.2253H9.52037V6.58775H7.49011V18.2253ZM12.4801 18.2253H14.5269V6.58775H12.4801V18.2253Z", fill: "#C93B49" }) })));
};
