import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../routes";
import { AuthProvider } from "../contexts/AuthContext";
import { AxiosInterceptor } from "../services/AxiosInterceptor";
import "./style.scss";
import { Footer } from "./pages/Footer";
function App() {
    return (_jsx(AuthProvider, { children: _jsx(React.StrictMode, { children: _jsx(Router, { children: _jsxs(AxiosInterceptor, { children: [_jsx(Routes, {}), _jsx(Footer, {})] }) }) }) }));
}
export { App };
