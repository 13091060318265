var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@mui/material";
import { IconClose } from "../../assets/icons/IconClose";
import "./style.scss";
export var ModalComponent = function (props) {
    var _a;
    var t = useTranslation().t;
    return (_jsx(Modal, __assign({ open: props.isModal, onClose: props.onClose }, { children: _jsxs("div", __assign({ className: "modal-box" }, { children: [_jsx("div", __assign({ className: "btn-close", onClick: props.onClose }, { children: _jsx(IconClose, {}) })), _jsx("p", __assign({ className: "title" }, { children: props.title })), _jsx("p", __assign({ className: "des" }, { children: props.description })), _jsx(Button, __assign({ variant: "contained", fullWidth: true, className: ["confirm", props.type === "DELETE" && "delete"].join(" "), onClick: props.onConfirm }, { children: props.textConfirm })), _jsx(Button, __assign({ variant: "outlined", fullWidth: true, className: "cancel", onClick: props.onCancel }, { children: (_a = props.textCancel) !== null && _a !== void 0 ? _a : t("cancel") }))] })) })));
};
