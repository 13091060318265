var ROUTES = {
    HOME: "/",
    LOGIN: "/login",
    PAGE_404: "/*",
    VIEW_BOTTLE: "/bottles/:id",
    VIEW_TERMS_CONDITIONS_FRONTEND: "/terms/frontend",
    VIEW_TERMS_CONDITIONS_MOBILE: "/terms/mobile",
    VIEW_TERMS_CONDITIONS_API: "/terms/api",
    VIEW_PRIVACY_POLICY: "/privacy",
};
export default ROUTES;
