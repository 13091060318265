var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, InputLabel, MenuItem, Select, } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { IconExpand } from "../../assets/icons/iconExpandDown";
var MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 243,
        },
    },
};
var SelectComponent = function (_a) {
    var value = _a.value, handleChange = _a.handleChange, options = _a.options;
    var t = useTranslation().t;
    return (_jsx("div", { children: _jsxs(FormControl, __assign({ className: "select-container", sx: { minWidth: 350 } }, { children: [_jsx(InputLabel, __assign({ id: "lable-select-winery" }, { children: t("chooseAWinery") })), _jsx(Select, __assign({ value: value, onChange: function (e) { return handleChange(e); }, label: "Choose a winery", sx: {
                        height: "52px",
                    }, IconComponent: function (_props) {
                        var rotate = _props.className.toString().includes("iconOpen");
                        return (_jsx("div", __assign({ style: {
                                position: "absolute",
                                cursor: "pointer",
                                pointerEvents: "none",
                                right: rotate ? 10 : 10,
                                top: rotate ? 10 : 15,
                                transform: rotate ? "rotate(180deg)" : "none",
                            } }, { children: _jsx(IconExpand, {}) })));
                    }, "data-testid": "select-winery", MenuProps: MenuProps }, { children: options &&
                        options.length > 0 &&
                        options.map(function (el) { return (_jsx(MenuItem, __assign({ value: el.value, sx: {
                                fontWeight: value === el.value ? "700" : "500",
                            } }, { children: _jsx(Box, __assign({ className: "label-select" }, { children: el.label })) }), el.value)); }) }))] })) }));
};
export { SelectComponent };
