export var headCellsAdmin = [
    {
        id: "lotNumber",
        numeric: false,
        label: "lotNumber",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "wineName",
        numeric: false,
        label: "wineName",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "origin",
        numeric: false,
        label: "origin",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "countryOfOrigin",
        numeric: false,
        label: "countryOfOrigin",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "producedBy",
        numeric: false,
        label: "producedBy",
        minWidth: "180px",
        sort: true,
    },
    {
        id: "producerAddress",
        numeric: false,
        label: "producerAddress",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "producerLatitude",
        numeric: false,
        label: "producerLatitude",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "producerLongitude",
        numeric: false,
        label: "producerLongitude",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "varietalName",
        numeric: false,
        label: "varietalName",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "vintageYear",
        numeric: false,
        label: "vintageYear",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "wineType",
        numeric: false,
        label: "wineType",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "wineColor",
        numeric: false,
        label: "wineColor",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "harvestDate",
        numeric: false,
        label: "harvestDate",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "harvestLocation",
        numeric: false,
        label: "harvestLocation",
        minWidth: "180px",
        sort: true,
    },
    {
        id: "pressingDate",
        numeric: false,
        label: "pressingDate",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "processingLocation",
        numeric: false,
        label: "processingLocation",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "fermentationVessel",
        numeric: false,
        label: "fermentationVessel",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "fermentationDuration",
        numeric: false,
        label: "fermentationDuration",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "agingRecipient",
        numeric: false,
        label: "agingRecipient",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "agingTime",
        numeric: false,
        label: "agingTime",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "storageVessel",
        numeric: false,
        label: "storageVessel",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "bottlingDate",
        numeric: false,
        label: "bottlingDate",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "bottlingLocation",
        numeric: false,
        label: "bottlingLocation",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "numberOfBottles",
        numeric: false,
        label: "numberOfBottles",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "action",
        numeric: true,
        label: "finalise/delete",
        minWidth: "150px",
        sort: false,
    },
];
export var headCellsWinery = [
    {
        id: "lotNumber",
        numeric: false,
        label: "lotNumber",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "wineName",
        numeric: false,
        label: "wineName",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "origin",
        numeric: false,
        label: "origin",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "countryOfOrigin",
        numeric: false,
        label: "countryOfOrigin",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "producedBy",
        numeric: false,
        label: "producedBy",
        minWidth: "180px",
        sort: true,
    },
    {
        id: "producerAddress",
        numeric: false,
        label: "producerAddress",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "producerLatitude",
        numeric: false,
        label: "producerLatitude",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "producerLongitude",
        numeric: false,
        label: "producerLongitude",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "varietalName",
        numeric: false,
        label: "varietalName",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "vintageYear",
        numeric: false,
        label: "vintageYear",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "wineType",
        numeric: false,
        label: "wineType",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "wineColor",
        numeric: false,
        label: "wineColor",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "harvestDate",
        numeric: false,
        label: "harvestDate",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "harvestLocation",
        numeric: false,
        label: "harvestLocation",
        minWidth: "180px",
        sort: true,
    },
    {
        id: "pressingDate",
        numeric: false,
        label: "pressingDate",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "processingLocation",
        numeric: false,
        label: "processingLocation",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "fermentationVessel",
        numeric: false,
        label: "fermentationVessel",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "fermentationDuration",
        numeric: false,
        label: "fermentationDuration",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "agingRecipient",
        numeric: false,
        label: "agingRecipient",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "agingTime",
        numeric: false,
        label: "agingTime",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "storageVessel",
        numeric: false,
        label: "storageVessel",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "bottlingDate",
        numeric: false,
        label: "bottlingDate",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "bottlingLocation",
        numeric: false,
        label: "bottlingLocation",
        minWidth: "200px",
        sort: true,
    },
    {
        id: "numberOfBottles",
        numeric: false,
        label: "numberOfBottles",
        minWidth: "150px",
        sort: true,
    },
    {
        id: "action",
        numeric: true,
        label: "approve",
        minWidth: "150px",
        sort: false,
    },
];
